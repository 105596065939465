export default{
    "stayLog":{
        "title":"Accesso alla webapp",
        "checkDate":{
            "label":"Data di check-in/check-out",
            "placeholder": "gg/mm/aaaa - gg/mm/aaaa",
        },
        "howPeople":"Quante persone soggiornano?",
        "button":"Avanti",
        "backButton":"Torna indietro",
        "addGuest":"+ Invia a un altro ospite",
        "deleteGuest":"Elimina",
        "guest": "Ospite",
        "processing":"Elaborazione",
    },
    "dataModal":{
        "title":"Soggiorno",
        "checkDate":{
            "label":"Data di check-in/check-out",
            "placeholder": "gg/mm/aaaa - gg/mm/aaaa",
        },
        "room":{
            "label":"Camera",
            "placeholder": "Numero di camera",
        },
        "howPeople": "Numero di ospiti che soggiornano",
        "howPeopleError":"Devi rimuovere ospiti per questa azione",
        "guestsTitle":"Ospiti",
        "guestLabel":"Ospite",
        "placehoderNull":"Il campo deve essere riempito",
        "phone":{
            "prefix":"+ Prefisso",
            "number": "Numero di telefono dell'ospite",
        },
        "cancelButton": "Annulla",
        "saveButton": "Salva",
    },
    "noSavedM":{
        "title":"Modifiche non salvate",
        "body":"Hai delle modifiche non salvate. Per aggiornare le informazioni devi salvare le modifiche effettuate.",
        "exitButton":"Esci senza salvare",
        "saveButton":"Salva",
    }
}
