export default{
    "stayLog":{
        "title":"Access to the webapp",
        "checkDate":{
            "label":"Check-in/Check-out date",
            "placeholder": "dd/mm/yyyy - dd/mm/yyyy",
        },
        "howPeople":"How many people will stay?",
        "button":"Next",
        "backButton":"Go back",
        "addGuest":"+ Send to another guest",
        "deleteGuest":"Delete",
        "guest": "Guest",
        "processing":"Processing",
    },
    "dataModal":{
        "title":"Stay",
        "checkDate":{
            "label":"Check-in/Check-out date",
            "placeholder": "dd/mm/yyyy - dd/mm/yyyy",
        },
        "room":{
            "label":"Room",
            "placeholder": "Room number",
        },
        "howPeople": "Number of guests who will stay",
        "howPeopleError":"You must remove guests for this action",
        "guestsTitle":"Guests",
        "guestLabel":"Guest",
        "placehoderNull":"The field must be filled",
        "phone":{
            "prefix":"+ Prefix",
            "number": "Guest's phone number",
        },
        "cancelButton": "Cancel",
        "saveButton": "Save",
    },
    "noSavedM":{
        "title":"Unsaved changes",
        "body":"You have unsaved changes. In order to update the information you must save the changes made.",
        "exitButton":"Exit without saving",
        "saveButton":"Save",
    }
}