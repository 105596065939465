export default {
    "breadcrumbs": {
        "explore": "Explore",
    },
    "list-page": {
        "title": "Explorer dans",
        "text-count-list": "{count} lieux trouvés",
        "btn-load-more": "Charger plus"
    },
    "results":'Sans résultats...',
    "seaching":'Recherche...',
    "categorySearch":'rencontrés',
    "city":"Ville",
    "cityPlaceholder":"Destinations...",
    "types":{
        "que-visitar":"Que visiter",
        "donde-comer":"Où manger",
        "ocio":"Loisirs"
    },
    "categories":{
        "monumentos":"Monuments",
        "museos":"Musées",
        "zonas-verdes":"Zones vertes",
        "cafeterias-y-postres":"Cafétérias et desserts",
        "restaurantes":"Restaurants",
        "vida-nocturna":"vie nocturne",
        "copas":"Tasses",
        "compras":"Shopping",
        "otros":"Autres",
    },
    "detail":{
        'titleList': "Explorer",
        "opinionsTitle":"Avis sur l'information touristique",
        "opinionsWord":"Opinions",
        "recommended": "Recommandé",
        "recommendationWord": "Recommandation ",
        "featured": "En vedette",
        "callWord": "Appel",
        "dataInterest":{
            "title":"Données d'intérêt",
            "priceRange":'Rangos de prix',
            "diet":"Régimes spéciaux",
            "others":"Autres",
        },
        "locationWord": "Emplacement",
        "starWord": "Étoile",
        "starsWord": "Étoiles",
        "ratingTitle": "Pointage",
        "noComment": "Pas de commentaire",
        "seeMoreComment": "Voir plus",
        "seeAllComments": "Voir tous les avis",
        "titleAllReviews": "Avis sur l'information touristique",
        "exploreIn":"Explorez dans",
        "filters":{
            "title": "Plus de filtres",
            "ratings": "Notes",
            "star": "étoile",
            "stars": "étoiles",
            "remove": "Supprimer",
            "apply": "Appliquer",
        }
    },
}