export default{
    "stayLog":{
        "title":"Acceso a la webapp",
        "checkDate":{
            "label":"Fecha Check-in/ Check-out",
            "placeholder": "dd/mm/aaaa - dd/mm/aaaa",
        },
        "howPeople":"¿Cuántas personas se alojarán?",
        "button":"Siguiente",
        "backButton":"Volver atrás",
        "addGuest":"+ Enviar a otro huésped",
        "deleteGuest":"Eliminar",
        "guest": "Huesped",
        "processing":"Procesando",
    },
    "dataModal":{
        "title":"Estancia",
        "checkDate":{
            "label":"Fecha Check-in/ Check-out",
            "placeholder": "dd/mm/aaaa - dd/mm/aaaa",
        },
        "room":{
            "label":"Habitación",
            "placeholder": "Nº habitación",
        },
        "howPeople":"Nº huéspedes que se alojarán",
        "howPeopleError":"Debes eliminar huéspedes para esta acción",
        "guestsTitle":"Huéspedes",
        "guestLabel":"Huésped",
        "placehoderNull":"El campo debe estar relleno",
        "phone":{
            "prefix":"+ Prefijo",
            "number": "Teléfono del huésped",
        },
        "cancelButton": "Cancelar",
        "saveButton": "Guardar",
    },
    "noSavedM":{
        "title":"Cambios sin guardar",
        "body":"Tienes cambios sin guardar. Para poder actualizar la información debes guardar los cambios realizados.",
        "exitButton":"Salir sin guardar",
        "saveButton":"Guardar",
    }
}
