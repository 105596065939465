export default {
    "breadcrumbs": {
        "explore": "Explore",
    },
    "list-page": {
        "title": "Explore in",
        "text-count-list": "{count} places found",
        "btn-load-more": "Load more"
    },
    "results":'Without results...',
    "seaching":'Searching...',
    "categorySearch":'found',
    "city":"City",
    "cityPlaceholder":"Destinations...",
    "types":{
        "que-visitar":"What to visit",
        "donde-comer":"Where to eat",
        "ocio":"Leisure"
    },
    "categories":{
        "monumentos":"Monuments",
        "museos":"Museums",
        "zonas-verdes":"Green zones",
        "cafeterias-y-postres":"Cafeterias and desserts",
        "restaurantes":"Restaurants",
        "vida-nocturna":"Night life",
        "copas":"Cups",
        "compras":"Shopping",
        "otros":"Others",
    },
    "detail":{
        'titleList': "Explore",
        "opinionsTitle":"Opinions on tourist information",
        "opinionsWord":"Opinions",
        "recommended": "Recommended",
        "recommendationWord": "Recommendation",
        "featured": "Featured",
        "callWord": "Call",
        "dataInterest":{
            "title":"Data of interest",
            "priceRange":'Price ranges',
            "diet":"Special diets",
            "others":"Others",
        },
        "locationWord": "Location",
        "starWord": "Star", 
        "starsWord": "Stars",
        "ratingTitle": "Rating",
        "noComment": "No comment written",
        "seeMoreComment": "See more",
        "seeAllComments": "See all opinions",
        "titleAllReviews": "Opinions on tourist information",
        "exploreIn":"Explore in",
        "filters":{
            "title":"More filters",
            "ratings":"Ratings",
            "star":"star",
            "stars":"stars",
            "remove":"Remove filters",
            "apply":"Apply filters",
        }
    },
}