export default{
    "stayLog":{
        "title": "Accès à la webapp",
        "checkDate":{
            "label": "Date d'arrivée/départ",
            "placeholder": "jj/mm/aaaa - jj/mm/aaaa",
        },
        "howPeople": "Combien de personnes resteront ?",
        "buuton": "Suivant",
        "backButton": "Retourner",
        "addGuest": "+ Envoyer à un autre invité",
        "deleteGuest": "Supprimer",
        "invite": "Invité",
        "traitement": "Traitement",
    },
    "dataModal":{
        "title": "Rester",
        "checkDate":{
            "label": "Date d'arrivée/départ",
            "placeholder": "jj/mm/aaaa - jj/mm/aaaa",
        },
        "room":{
            "label": "Chambre",
            "placeholder": "Numéro de chambre",
        },
        "howPeople": "Nombre d'invités qui resteront",
        "howPeopleError":"Vous devez supprimer des invités pour cette action",
        "guestsTitle": "Invités",
        "guestLabel": "Invité",
        "placehoderNull": "Le champ doit être rempli",
        "phone":{
            "prefix": "+ Préfixe",
            "number": "Numéro de téléphone du client",
        },
        "cancelButton": "Annuler",
        "saveButton": "Enregistrer",
    },
    "noSavedM": {
        "title": "Modifications non enregistrées",
        "body": "Vous avez des modifications non enregistrées. Afin de mettre à jour les informations, vous devez enregistrer les modifications apportées.",
        "exitButton": "Quitter sans sauvegarder",
        "saveButton": "Enregistrer",
    }
}