export default {
    "breadcrumbs": {
        "explore": "Explorar",
    },
    "list-page": {
        "title": "Explorar em",
        "text-count-list": "{count} lugares encontrados",
        "btn-load-more": "Carregar mais"
    },
    "results": "Sem resultados...",
    "seaching": "Pesquisando...",
    "categorySearch": "encontrado",
    "city": "Cidade",
    "cityPlaceholder": "Destinos...",
    "types": {
        "que-visitar": "O que visitar",
        "donde-comer": "Onde comer",
        "ocio": "Lazer"
    },
    "categories": {
        "monumentos": "Monumentos",
        "museos": "Museus",
        "zonas-verdes": "Zonas verdes",
        "cafeterias-y-postres": "Cafeterias e sobremesas",
        "restaurantes": "Restaurantes",
        "vida-nocturna": "Vida noturna",
        "copas": "Copos",
        "compras": "Compras",
        "otros": "Outros",
    },
    "detail": {
        "titleList": "Explorar",
        "opinionsTitle": "Opiniões sobre informações turísticas",
        "opinionsWord": "Opiniões",
        "recommended": "Recomendado",
        "recommendationWord": "Recomendação",
        "featured": "Destacado",
        "callWord": "Ligar",
        "dataInterest": {
            "title": "Dados de interesse",
            "priceRange": "Faixas de preço",
            "diet": "Dietas especiais",
            "others": "Outros",
        },
        "locationWord": "Localização",
        "starWord": "Estrela",
        "starsWord": "Estrelas",
        "ratingTitle": "Classificação",
        "noComment": "Nenhum comentário escrito",
        "seeMoreComment": "Ver mais",
        "seeAllComments": "Ver todas as opiniões",
        "titleAllReviews": "Opiniões sobre informações turísticas",
        "exploreIn": "Explorar em",
        "filters": {
            "title": "Mais filtros",
            "ratings": "Avaliações",
            "star": "estrela",
            "stars": "estrelas",
            "remove": "Remover filtros",
            "apply": "Aplicar filtros",
        }
    },
}
