export default{
    "stayLog":{
        "title":"Zugang zur Webanwendung",
        "checkDate":{
            "label":"Check-in/Check-out Datum",
            "placeholder": "TT/MM/JJJJ - TT/MM/JJJJ",
        },
        "howPeople":"Wie viele Personen werden bleiben?",
        "button":"Weiter",
        "backButton":"Zurückgehen",
        "addGuest":"+ An einen anderen Gast senden",
        "deleteGuest":"Löschen",
        "guest": "Gast",
        "processing":"Verarbeitung",
    },
    "dataModal":{
        "title":"Aufenthalt",
        "checkDate":{
            "label":"Check-in/Check-out Datum",
            "placeholder": "TT/MM/JJJJ - TT/MM/JJJJ",
        },
        "room":{
            "label":"Zimmer",
            "placeholder": "Zimmernummer",
        },
        "howPeople": "Anzahl der Gäste, die bleiben werden",
        "howPeopleError":"Sie müssen Gäste entfernen, um diese Aktion durchzuführen",
        "guestsTitle":"Gäste",
        "guestLabel":"Gast",
        "placehoderNull":"Das Feld muss ausgefüllt werden",
        "phone":{
            "prefix":"+ Vorwahl",
            "number": "Telefonnummer des Gastes",
        },
        "cancelButton": "Abbrechen",
        "saveButton": "Speichern",
    },
    "noSavedM":{
        "title":"Nicht gespeicherte Änderungen",
        "body":"Sie haben nicht gespeicherte Änderungen. Um die Informationen zu aktualisieren, müssen Sie die vorgenommenen Änderungen speichern.",
        "exitButton":"Ohne Speichern verlassen",
        "saveButton":"Speichern",
    }
}
