export default {
    "breadcrumbs": {
        "explore": "Erkunden",
    },
    "list-page": {
        "title": "Erkunden in",
        "text-count-list": "{count} Orte gefunden",
        "btn-load-more": "Mehr laden"
    },
    "results": "Keine Ergebnisse...",
    "seaching": "Suche...",
    "categorySearch": "gefunden",
    "city": "Stadt",
    "cityPlaceholder": "Ziele...",
    "types": {
        "que-visitar": "Was zu besuchen",
        "donde-comer": "Wo essen",
        "ocio": "Freizeit"
    },
    "categories": {
        "monumentos": "Denkmäler",
        "museos": "Museen",
        "zonas-verdes": "Grünflächen",
        "cafeterias-y-postres": "Cafés und Desserts",
        "restaurantes": "Restaurants",
        "vida-nocturna": "Nachtleben",
        "copas": "Becher",
        "compras": "Einkaufen",
        "otros": "Andere",
    },
    "detail": {
        "titleList": "Erkunden",
        "opinionsTitle": "Meinungen zu touristischen Informationen",
        "opinionsWord": "Meinungen",
        "recommended": "Empfohlen",
        "recommendationWord": "Empfehlung",
        "featured": "Hervorgehoben",
        "callWord": "Anrufen",
        "dataInterest": {
            "title": "Interessante Daten",
            "priceRange": "Preisspannen",
            "diet": "Spezielle Diäten",
            "others": "Andere",
        },
        "locationWord": "Standort",
        "starWord": "Stern",
        "starsWord": "Sterne",
        "ratingTitle": "Bewertung",
        "noComment": "Kein Kommentar geschrieben",
        "seeMoreComment": "Mehr sehen",
        "seeAllComments": "Alle Meinungen ansehen",
        "titleAllReviews": "Meinungen zu touristischen Informationen",
        "exploreIn": "Erkunden in",
        "filters": {
            "title": "Weitere Filter",
            "ratings": "Bewertungen",
            "star": "Stern",
            "stars": "Sterne",
            "remove": "Filter entfernen",
            "apply": "Filter anwenden",
        }
    },
}
