export default{
    "stayLog":{
        "title":"Acesso à webapp",
        "checkDate":{
            "label":"Data de check-in/check-out",
            "placeholder": "dd/mm/aaaa - dd/mm/aaaa",
        },
        "howPeople":"Quantas pessoas vão ficar?",
        "button":"Próximo",
        "backButton":"Voltar",
        "addGuest":"+ Enviar para outro hóspede",
        "deleteGuest":"Excluir",
        "guest": "Hóspede",
        "processing":"Processando",
    },
    "dataModal":{
        "title":"Estadia",
        "checkDate":{
            "label":"Data de check-in/check-out",
            "placeholder": "dd/mm/aaaa - dd/mm/aaaa",
        },
        "room":{
            "label":"Quarto",
            "placeholder": "Número do quarto",
        },
        "howPeople": "Número de hóspedes que vão ficar",
        "howPeopleError":"Você deve remover hóspedes para esta ação",
        "guestsTitle":"Hóspedes",
        "guestLabel":"Hóspede",
        "placehoderNull":"O campo deve ser preenchido",
        "phone":{
            "prefix":"+ Prefixo",
            "number": "Número de telefone do hóspede",
        },
        "cancelButton": "Cancelar",
        "saveButton": "Salvar",
    },
    "noSavedM":{
        "title":"Mudanças não salvas",
        "body":"Você tem mudanças não salvas. Para atualizar as informações, você deve salvar as mudanças feitas.",
        "exitButton":"Sair sem salvar",
        "saveButton":"Salvar",
    }
}
